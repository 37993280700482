@headerHeight: 50px;
@transitionDuration: 300ms;
@layout-header-background: #001529;
@primary-color: #18bfff;

.side {
    position: fixed;
    z-index: 999;
    top: @headerHeight;
    left: 0;
    bottom: 0;
    width: 200px;
    box-shadow: 0 4px 4px 2px #ddd;
    background: #fff;
    transition: all @transitionDuration;

    .outer {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: all @transitionDuration;
    }

    .inner {
        position: relative;
        height: 100%;
        overflow-x: visible;
        overflow-y: scroll;
        transition: all @transitionDuration;
        -webkit-overflow-scrolling: touch;
    }

    :global {
        .ant-menu-item-selected {
            z-index: 1;
        }
    }

    .drag-bar {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 100%;
        cursor: col-resize;
        z-index: 1;
    }

    .drag-bar-icon {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        right: -9px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 20px 0;
        width: 6px;
        height: 100px;
        //background: #fff;
        //border: 1px solid #e8e8e8;
        border-left: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: col-resize;

        span {
            display: block;
            width: 2px;
            height: 2px;
            background: #bbb;
            border-radius: 50%;
        }
    }
}

@primary-color: #2F54EB;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;