:global .readonly-form-wrapper {
  height: 100%;
  overflow-y: auto;
}
:global .readonly-form-wrapper .ant-input[disabled],
:global .readonly-form-wrapper .ant-cascader-picker-label,
:global .readonly-form-wrapper .ant-input-number-disabled,
:global .readonly-form-wrapper .ant-select-disabled,
:global .readonly-form-wrapper .ant-radio-disabled + span {
  color: #555;
}
:global .avatar-uploader .ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0;
}
:global .draggable-tabs-bar-horizontal-item .draggable-tabs-bar-horizontal-item-inner {
  min-width: 150px;
}
